<template>
  <div class="navMenuBox">
     <el-menu
      :default-active='activeMenu'
      class="navMenuContent"
      :unique-opened="true"
      @open="handleOpen"
      @close="handleClose"
      @select="handleSelect"
      background-color="#fff"
      text-color="#000"
      active-text-color="#ffd04b">

      <template v-for="(item,index) in menuList">
        <router-link v-if="(!item.children || !item.children.length)" :to="'/' + item.code" :key="item.id+index">
            <el-menu-item :index="`${item.id}`">
                <i class="el-icon-location"></i>
                <span>{{item.title}}</span>
            </el-menu-item>
        </router-link>

        <el-submenu v-if="item.children&&item.children.length" :index="`${item.id}`">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>{{item.title}}</span>
          </template>
          <el-menu-item-group>
            <template v-for="(attr,idx) in item.children">
              <router-link :to="'/' + attr.code" :key="item.id+idx">
                <el-menu-item :index="`${attr.id}`">{{attr.title}}</el-menu-item>
              </router-link>
            </template>
          </el-menu-item-group>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
import {getToken} from '@/utils/auth'
export default {
  data() {
    return {
      activeMenu:'',
      menuList:[]
    }
  },
  created() {
    },
  mounted() {
    this.setMenuFun()
  },
  methods:{
    setMenuFun(){
      let personalList = [
        {
          title:'首页公告信息',
          id:1,
          code:'notice'
        },
        {
          title:'招聘岗位报名',
          id:2,
          code:'recruitPost'
        },
        {
          title:'个人报名记录',
          id:3,
          code:'personalRecord'
        },
      ]
      let menuList = [
        {
          title:'系统管理',
          id:1,
          code:'about',
          children:[
            {
              title:'后台用户管理',
              id:11,
              code:'userManagement',
            },
            {
              title:'业务字典管理',
              id:12,
              code:'dictionaries',
            },

          ]
        },
        {
          title:'公告信息管理',
          id:2,
          code:'noticeInformation'
        },
        {
          title:'招聘事项管理',
          id:3,
          code:'recruitmentMatters'
        },
        {
          title:'报名审核管理',
          id:4,
          code:'registrationReview'
        },
        {
          title:'报名统计查询',
          id:5,
          code:'registrationStatistics'
        },
      ]
      let token = getToken()=='undefined'?'':getToken();
      let ownTo = window.sessionStorage.getItem('ownToken');
      let activeObj = {};
      // 判断是个人还是管理
      if(ownTo){
        this.menuList = personalList;
        if(this.$route.name&&(this.$route.name!='Home')){
          activeObj = personalList.filter(item=>{
            return item.code==this.$route.name
          })[0];
          this.activeMenu = `${activeObj.id}`;
        }else{
          this.activeMenu = '1';
        }

      }else if(token){
        this.menuList = menuList;
        if(this.$route.name&&(this.$route.name!='Home')){
          activeObj = this.menuList.filter(item=>{
            return item.code==this.$route.name
          })[0];
          if(activeObj){
            this.activeMenu = `${activeObj.id}`;
          }else{
            activeObj = menuList[0].children.filter(item=>{
              return item.code==this.$route.name
            })[0]
          }
          this.activeMenu = `${activeObj.id}`;
        }else{
          this.activeMenu = '2';
        }
      }
    },
    handleSelect(key,keyPath){
      console.log(key);
      this.activeMenu = String(key);
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    }
  }
}
</script>
<style lang="scss">
  .navMenuBox{
    width: 250px;
    height: calc(100vh - 155px);
    box-shadow: 0px 1px 3px #888888;

    .navMenuContent{
      height: 100%;
      .el-menu-item.is-active{
        background: #bf162a!important;
        color: #fff !important;
      }
      .el-menu-item-group__title{
        display: none;
      }
    }
  }
</style>