<template>
  <div class="homeBox">
    <!-- 头部 -->
    <div class="homeHeader">
      <div class="imgBox">
        <img :src="loginHeader" alt="">
      </div>
      <div @click="signOut" class="signOutSty">退出</div>
    </div>

    <div class="contnetBg">
      <div class="navBox">
        首页 > {{navObj}}
      </div>

      <div class="homeContent">
        <!-- 左侧菜单 -->
        <nav-Menu />
        <!-- 右测内容 -->
        <div class="rightContent">
          <router-view />
        </div>
      </div>
    </div>

    <div class="footerBox">
      Copyright © 2018-2028 www.wizhrs.com Powered By 北京万智国际人力资源服务有限公司 <span style="margin-left:5px">ICP备案号：</span><a style="color: #999999;" target="_blank" href="https://beian.miit.gov.cn/">京ICP备18042429 号</a>
    </div>
  </div>
</template>

<script>
import navMenu from '@/components/navMenu.vue'
import loginHeader from '@/assets/loginHeader.png'
import {removeToken} from '@/utils/auth'
import { mapGetters,mapMutations} from 'vuex'
export default {
  name: 'Home',
  components:{
    navMenu
  },
  data() {
    return {
      loginHeader,
      title:''
    }
  },
  mounted() {

  }, 
  computed:{
    ...mapGetters(['navObj'])
  },
  methods:{
    ...mapMutations(['SET_FLAG']),
    signOut(){
      removeToken()
      window.sessionStorage.removeItem('ownToken')
      window.sessionStorage.removeItem('time')
      this.SET_FLAG(true)
      this.$router.replace('/login')
    }
  },
}
</script>
<style lang="scss">
  .homeBox{
    min-width: 900px;
    overflow: auto;
    .homeHeader{
      width: 100%;
      box-sizing: border-box;
      height: 65px;
      background: rgb(191,22,42);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 100px;
      .imgBox{

      }

      .signOutSty{
        color: #fff;
        cursor: pointer;
      }
    }
    .contnetBg{
      height: calc(100vh - 95px);
      background: url('../assets/homeBg.jpg') no-repeat;
      background-size: 100% 100%;
      position: relative;
      .navBox{
        height: 32px;
        background: rgba(0,0,0,.2);
        display: flex;
        align-items: center;
        padding: 0 100px;
        color: #fff;
        font-size: 14px;
      }

      .homeContent{
        display: flex;
        // position: absolute;
        justify-content: center;
        margin-top: 10px;
        // left: 100px;
        .rightContent{
          width: calc(100vw - 460px);
          background: #fff;
          margin-left: 10px;
          box-shadow: 0px 1px 3px #888888;
          min-width: 600px;
          overflow-x: auto;
        }
      }
    }

    .footerBox{
      background: #555555;
      color: #999999;
      text-align: center;
      height: 30px;
      line-height: 30px;
      font-size: 12px;
    }
  }
</style>
